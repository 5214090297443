/* Tooltip */
(function($){
	'use strict';
	
	var tooltipTemplate = (title = "", content = "", type = 'info', position = 'right') => `
		<div class="tooltip tooltip--${ type } tooltip--${ position }">
			<span class="tooltip__arrow tooltip__arrow"></span>
			<div class="tooltip__title">${ title }</div>
			<div class="tooltip__content">
				${ content }
			</div>
		</div>
	`,
	$body	= $('body'),
	$window = $(window),
	$tooltips = $('.tooltip__link');

	$tooltips.each(function(i, elem){
		var $tooltip = $(elem),
			$tip = $(tooltipTemplate(
					$tooltip.data('title'),
					$tooltip.data('content'),
					$tooltip.data('type'),
					$tooltip.data('position')
				)),
			positionSet = function(){
				var $position = $tooltip.position();
				if($tooltip.data('position') === undefined || $tooltip.data('position') === 'right'){
					$tip.css({
						top: $position.top,
						left: $position.left + $tooltip.outerWidth()
					});
				} else {
					$tip.css({
						top: $position.top,
						left: $position.left - ($tip.outerWidth() + $tooltip.outerWidth() )
					});
				}
			};

		$tooltip.parent().append( $tip );


		positionSet();
		$window.on('resize', positionSet);

		$tooltip.hover(function(){
			$tip.stop().fadeToggle();
		});

	});

}(jQuery));