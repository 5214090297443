(function($) {
	// generate date
	function getFormattedDate() {
		var date = new Date();
		var str =
			date.getFullYear() +
			"-" +
			(date.getMonth() + 1) +
			"-" +
			date.getDate() +
			" " +
			date.getHours() +
			":" +
			date.getMinutes() +
			":" +
			date.getSeconds();

		return str;
	}
	// Date Picker
	if($('[data-kendo="datePicker"]').length > 0) {
		$('[data-kendo="datePicker"]').kendoDateTimePicker({
			format: "yyyy-MM-dd HH:mm:ss",
			value: new Date()
		});
	}

	if($('[data-kendo="kendoGrid"]').length > 0) {
		var exportableGrid = $('[data-kendo="kendoGrid"]').kendoGrid({
			filterable: {
				extra: false
			},
			excel: {
				fileName:
					"Cenex CRM - " +
					window.location.pathname.split("/")[1] +
					" - " +
					getFormattedDate() +
					".xlsx",
				allPages: true
			},
			scrollable: true,
			sortable: {
				mode: "single", // multiple
				allowUnsort: false // true
			},
			columnMenu: false,
			groupable: true,
			resizable: true,
			pageable: {
				pageSize: 25,
				pageSizes: [10, 25, 50, 100, "all"]
			},
			columns: typeof kendoGridColumns !== undefined ? kendoGridColumns : []
		});
	}

	if($('#exportGrid').length > 0) {
		$("#exportGrid").on("click", function(e) {
		e.preventDefault();
		// Set Fields that need to be excluded from the export
		var exportHeaderExclusion = [
			"Archive",
			"Log",
			"Edit",
			"Contacts",
			"View"
		];

		// Check to see if the grid exsits
		if (exportableGrid.length > 0) {
			// Fetch data from teh grid object
			var data = exportableGrid.data("kendoGrid");

			// iterate over columns
			$.each(data.columns, function(key, col) {
				// check property against exclusion list
				$.each(exportHeaderExclusion, function(i, toHide) {
					// set to hidden
					if (col.field === toHide) {
						data.columns[key].hidden = true;
					}
				});
			});

			data.saveAsExcel();
		} else {
			alert(
				"Unable to download current selection as there is data grid available."
			);
		}
	});
	}

	// Country / Region Filter
	if($('#country_region').length > 0) {

		function showOrHideCountryRegionOtherContainer(otherContainer, currentVal, countryRegionOther) {
			// If the current value is set to 'Other', show the container
			if(currentVal == 'Other') {
				otherContainer.show();
			} else {
				countryRegionOther.val('');
				otherContainer.hide();
			}
		}

		var countryRegion = $('#country_region');
		var countryRegionOther = $('#country_region_other');
		var container = $('#country_region_other_container');
		var initialValue = countryRegion.val();

		// Run the showOrHideCountryRegionOtherContainer method.
		showOrHideCountryRegionOtherContainer(container, initialValue, countryRegionOther);

		// Handle on select.
		$('#country_region').on('change', function(e) {
			var selectedValue = $(this).val();
			showOrHideCountryRegionOtherContainer(container, selectedValue, countryRegionOther)
		});
	}

})(jQuery);
