/* Form Inputs and Labels */
(function($){
	'use strict';

	var $form = $('.form'),
		$formFields = $form.find('input');

	$formFields.each(function(i, elem){
		var $input = $(elem),
			$field = $input.parent(),
			isEmpty = function(input) { return $input.val().trim().length === 0 };

		$input.on('focus', function(){
			$field.removeClass('form__field--empty');
		});

		$input.on('change blur', function(){
			if (isEmpty()) {
				$field.addClass('form__field--empty');
			}
		});

		$input.on('change blur', function(){
			if ($input.is(':invalid')) {
				$field.addClass('form__field--error');
			}
			if ($input.is(':valid')) {
				$field.removeClass('form__field--error');
			}
		});

		if (isEmpty()) {
			$field.addClass('form__field--empty');
		}

		$input.attr('tabindex',i+1);

	});

}(jQuery));