/* Dropdown */
(function($){

	'use strict';

	var $body = $('body'),
		$dropdown = $('.dropdown');

	$body.on('click', '.dropdown', function(e) {
		e.stopPropagation();
		$dropdown.not(this).find('.dropdown__menu:visible').slideUp('fast');
		$(this).find('.dropdown__menu').slideToggle('fast');
	});

}(jQuery));