/* Side Bars */
(function($){

	'use strict';

	var $window = $(window),
		$app = $('.app'),
		$headerToggle = $('.header__toggle'),
		$actionToggle = $('.actions__toggle'),
		hasSidebar = $app.hasClass('app--with-sidebar') ? true : false,
		hasActions = $app.hasClass('app--with-actions') ? true : false,
		SIDEBAR_CLASS = hasSidebar ? 'app--with-sidebar' : 'app--sidebar-open',
		ACTION_CLASS = hasActions ? 'app--with-actions' : 'app--actions-open',
		mobileNavigation = function(){
			var ww = $window.width();
			if(ww <= 1200) {
				$app.removeClass('app--with-sidebar app--with-actions');
				$headerToggle.removeClass('header__toggle--open');
				$actionToggle.removeClass('action__toggle--open');
				SIDEBAR_CLASS = 'app--sidebar-open';
				ACTION_CLASS = 'app--actions-open';
			} else {
				if(hasSidebar) {
					SIDEBAR_CLASS = 'app--with-sidebar';
					$app.addClass(SIDEBAR_CLASS);
					$headerToggle.addClass('header__toggle--open');
				}
				if(hasActions) {
					ACTION_CLASS = 'app--with-actions';
					$app.addClass(ACTION_CLASS);
					$actionToggle.addClass('action__toggle--open');
				}
			}
		};

	if($app.hasClass('app--with-sidebar')){
		$headerToggle.addClass('header__toggle--open');
	}

	if($app.hasClass('app--with-actions')){
		$actionToggle.addClass('action__toggle--open');
	}

	$headerToggle.on('click', function(e){
		e.preventDefault();
		$headerToggle.toggleClass('header__toggle--open');
		$app.removeClass('app--actions-open');
		$app.toggleClass(SIDEBAR_CLASS);
		$window.trigger('resize',[true]);
	});
	
	$actionToggle.on('click', function(e){
		e.preventDefault();
		$actionToggle.toggleClass('action__toggle--open');
		$app.removeClass('app--sidebar-open');
		$app.toggleClass(ACTION_CLASS);
		$window.trigger('resize',[true]);
	});

	mobileNavigation();
	$window.on('resize', function(event, param){ 
		if(param === true) return false;
		mobileNavigation();
	});

}(jQuery));